@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  font-family: "Open Sans", sans-serif;

}
:root {
  --white: #fff;
  --primary:#3498DB;
  --secondary:#0080FC;
  --warning:#FDB657;
  --critical:#FC717A;
  --natural:#333333;
  --success:#1BCB81;
  --li_bg:#488ee2;
  --li_color:rgb(94, 102, 112);
  --main_bg:#ededf1;
  --b_Red:red;
  --rating:#dcb751;
  --order_header_bg:#c9cdec;
  --customer_header_bg:#E8CF8F;
}

body {
  background-color: var(--main_bg);

}

/* Track */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 100px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5c5f62;
  border-radius: 100px;
}

 .custom-toast-container {
  .Toastify__toast {
    font-size: 12px;
  }
  .Toastify {
    height: 20px;
  }
}


/* Admin Login */
.admin_login {
  height: 100vh; /* Full viewport height */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  /* background-image: url('./assets//images//sideimage.jpg'); */
  background-position: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.admin_login form{
  position:relative;
}
.admin_login form .image{
  height: 170px;
}
.admin_login form .image img{
  height: 100%;
}
.admin_login form input{
  width: 100%;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 35px;
  color: var(--primary);
  width: 100%;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.admin_login form input:focus{
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.admin_login form input::placeholder{
  color: var(--primary);

}
.admin_login form svg{
  position: relative;
  height: 20px;
  top: 29px;
  left: 8px;
  color: var(--primary);

}
.admin_login form button{
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: var(--primary);
  color: white;
  box-shadow: 1px 1px 1px 1px solid black;
}
.admin_login form button:hover{
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--primary);;
  background: none;
  color:  var(--primary);
  border: 1px solid var(--primary);
}
.admin_login form button:focus{
outline: none;
}
.admin_login form .error{
border: 1px solid red;
}


.admin_login form a{
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1px;
  color:var(--primary) ;
}

.admin_login .side_image{
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.admin_login .side_image img{
  height: 100%;
  width: 100%;
}


/* Sidebar */


.sidenav::-webkit-scrollbar {
  width: 0px;
  border-radius: 100px;

}

.sidenav::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border-radius: 100px;

}

.sidenav::-webkit-scrollbar-thumb:hover {
  border-radius: 100px;

}

.sidenav::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 100px;
}

.sidenav::-webkit-scrollbar-track:hover {
  background-color: none
}

.sidenav {
  z-index: 999;
  height: 100%;
  width: 200px;
  position: fixed;
  overflow-x: hidden;
  background-color: var(--natural);
  overflow-y: auto; /* Use overflow-y for vertical scrolling */
  top: 0;
  border-right: 1px solid var(--mediumgrey);
  transition: width 0.5s ease-in-out;
}
.sidenav ul {
  list-style-type: none;
  position: relative;
  }
  
  .sidenav .welcome h4 {
  color: var(--white);
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Edu AU VIC WA NT Hand", cursive;  
  }
  .sidenav .welcome img{
    height: 0px;
    width: 0px;
    display: none;
  }

  
  .sidenav li {
  width: 95%;
  color: white;
  margin-left: 0px;
  padding: 4px 6px 4px 6px;
  margin-top: 2px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  }
  .sidenav ul li svg{
  height: 28px;
  padding: 5px;
  color: var(--primary);
  }
  
  .sidenav li:hover {
  cursor: pointer;
  background-color: var(--li_bg);
  border-radius: 4px;
  padding-left: 8%;
  margin-top: 2px;
  width: 95%;
  color: var(--white);
  transition: background-color 0.9s, transform 0.5s;
  transform: scale(1.05); 
  }
  
  .sidenav ul p {
  color: #204b66;
  padding-left: 10%;
  font-size: 12px;
  font-weight: 700;
  }
  
  .sidenav .dropdown button {
  width: 100%;
  background: none;
  }
  
  .sidenav img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  }


.main {
  margin-left: 200px;
  background-color: var(--main_bg);
  overflow: scroll;
  transition: margin 0.5s ease-in-out;
}
.collapsed{
  margin-left: 70px;
  background-color: var(--main_bg);
  overflow: scroll;
  transition: margin 0.5s ease-in-out;
}

.collapsed_sidenav {
  z-index: 999;
  height: 100%;
  width: 70px;
  position: fixed;
  overflow-x: hidden;
  background-color: var(--natural);
  overflow-y: auto; /* Use overflow-y for vertical scrolling */
  top: 0;
  border-right: 1px solid var(--mediumgrey);
  transition: width 0.5s ease-in-out;
}
.collapsed_sidenav .welcome h4 {
  font-size: 0px;  
  }
  .collapsed_sidenav .welcome img{
    height: 55px;
    width: 55px;
  }

.collapsed_sidenav ul {
  list-style-type: none;
  position: relative;
  }
  

.collapsed_sidenav li {
  color:var(--primary);
  margin-left: 0px;
  margin-top: 10px;
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  }
  
  .collapsed_sidenav li span{
    font-size: 0px;
  }
  .collapsed_sidenav ul li svg{
  height: 26px;
  }
  
  .collapsed_sidenav li:hover {
  cursor: pointer !important;
  background-color: var(--primary);
  border-radius: 4px;
  margin-top: 2px;
  color: var(--white);
  transition: background-color 0.9s, transform 0.5s;
  transform: scale(1.05); 
  }
  
  .collapsed_sidenav ul p {
  color: #204b66;
  padding-left: 10%;
  font-size: 12px;
  font-weight: 700;
  }
  
  .collapsed_sidenav .dropdown button {
  width: 100%;
  background: none;
  }
  
  .collapsed_sidenav img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  }


  /* For screen sizes less than 400px */
@media (max-width: 450px) {
  .main {
    margin-left: 0px;
    transition: margin 0.5s ease-in-out;
  }
  .collapsed{
    margin-left: 0px;
  }
  .sidenav{
    width: 0px;
  }
}

/* Navbar */
.my_Navbar{
  position: relative;
}
#mycheck{
  -webkit-appearance:none ;
  visibility: none;
  display: none;
}
.my_Navbar .my_span{
  position: absolute;
  top: 23px;
  left: 15px;
  font-size: 20px;
  border-radius: 3px;
  height: 20px;
  padding: 3px 8px;
  cursor: pointer;
  background:white;
}
.my_Navbar .left svg{
  height: 25px;
  position: absolute;
  top: 23px;
  left: 15px;
  border-radius: 3px;
  height: 20px;
  cursor: pointer;
  background:white;
}

.my_Navbar button{
  border: 0px;
  background: none;
}

#mycheck:checked ~ .sidenav h4{
  display: none;
  visibility: none;
}


.my_Navbar .left h4{
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px; 
  margin-left: 35px;
  color: var(--primary);
  text-transform: capitalize;
}

.my_Navbar .right ul{
  list-style: none;
}
.my_Navbar .right ul li{
  display: inline-block;
  font-size: 12px;
  color: var(--li_color);
  cursor: pointer;
}
.my_Navbar .right ul .notify{
  border-radius: 6px;
  border: 1px solid var(--li_color);
}
.my_Navbar .right ul .notify svg{
  height: 15px;
}
.my_Navbar .right ul .day{
  border: 1px solid var(--li_color);
  border-radius: 6px;
  font-size: 12px;

  font-weight: 500;
}
.my_Navbar .right ul .day svg{
  height: 15px;
  margin-right: 10px;
}
.my_Navbar .right ul li svg{
height: 20px;
color: var(--li_color);
}

/* Dashboard */
 .card{
border: none;
border-radius: 7px;
}

 .card .top_content .left h6{
  font-size: 14px;
  font-weight: 600;
  display: inline;
  color: white;
  
}

 .card .top_content .right p{
  font-size: 13px;
  display: inline;
  color: white;
  font-weight: 700;
  letter-spacing: 0.6px;
}
 .card hr{
  color: white;
  
}

 .card p{
  font-size: 11px;
  color: white;
  font-weight: 400;
  padding: 0;
}

/* orders_received card */
.orders_received {
  background: #ED6A3F;
;}
.orders_received svg{
  display: inline;
  height: 30px;
  color: #ED6A3F;
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}
/* orders_processing card */
 .orders_processing{
  background:#897CF7;

}

.orders_processing svg{
  display: inline;
  height: 30px;
  color: #897CF7;
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}
/* orders_completed card */
 .orders_completed{
  background: #1EC6CB;

}

.orders_completed svg{
  display: inline;
  height: 30px;
  color: #1EC6CB;
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}

/* orders_delivered card */
 .orders_delivered{
  background: #508DFC;
}

.orders_delivered svg{
  display: inline;
  height: 30px;
  color: #508DFC;
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}

/* Tailors Cards*/
 .inactive_shops{
  background: rgb(177, 78, 78)

}
.inactive_shops svg{
  display: inline;
  height: 30px;
  color: rgb(177, 78, 78);
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}
 .top_shops{
  background: #dcb751;
  /* background: linear-gradient(to right,#ac9453 , #dcb751); */
}
.top_shops svg{
  display: inline;
  height: 30px;
  color: #dcb751;
  padding: 6px;
  border-radius: 100px;
  background: white; /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
}
/* Dashboard Orders Table */
 .data_table{
  background-color: white;
  border: none;
border-radius: 7px;
}
 

/* Search Bar in Dashboard table */
 .data_table .searchbar{
  position: relative;
}
 .data_table .searchbar h6{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6;
}
.data_table .searchbar h6 span{
  font-size: 15px;
  
}

.data_table .searchbar h5{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.6;
  color: black;
}

 .data_table .searchbar input{
  height: 35px;
  background: var(--main_bg);
  font-size: 12px;
  box-shadow: 1px 1px 1px 1px solid black;
  border:none;
  border-radius: 4px;
  padding-left: 35px;
  color: var(--li_color);
  cursor:auto;
  width: 100%;
}
.data_table .searchbar select{
  height: 35px;
  font-size: 12px;
  background: var(--main_bg);
  box-shadow: 1px 1px 1px 1px solid black;
  border:none;
  padding-left: 12px;
  border-radius: 4px;
  color: var(--li_color);
  text-transform: capitalize;
}
.data_table .searchbar .add_btn{
  height: 35px;
  width: 60px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid var(--primary);
  background-color: transparent;
  color: white;
  min-width: 40px;
  background-color: var(--primary);
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.data_table .searchbar input:focus,.data_table .searchbar select:focus{
  outline: none;
}
 .data_table .searchbar .search_icon_svg{
  height: 16px;
  position: relative;
  top: 10px;
  left: 25px;
}

.data_table .searchbar .filter_btn{
  background-color: white;
border: none;
}
.data_table .searchbar .filter_btn svg{
  height: 20px;
}
/*Dashboard Table styling*/
.data_table .order_header {
  background-color: var(--order_header_bg);
}
.data_table .order_header .th{
  font-size: 11px;
  color: white;
  white-space: nowrap;
  cursor: pointer;
}

.data_table .customer_header {
  background-color: var(--customer_header_bg);
}
.data_table .customer_header .th{
  font-size: 11px;
  color: white;
  white-space: nowrap;
}
 .data_table table .th{
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  white-space: nowrap;
  cursor: pointer;
}
.data_table table .th i{
  font-size: 10px;
  color: var(--primary);
}
 .data_table table .td{
  font-size: 11px;
  font-weight: 400;
  color: var(--li_color);
  text-transform: capitalize;
}
.data_table table .td .play_btn,
.data_table table .td .stop_btn {
  background: var(--primary);
  font-size: 12px;
  border: none;
  border-radius: 2px;
  color: white;
  border-radius: 100%;
}

.data_table table .td .stop_btn {
  background: var(--b_Red);
  border-radius: 100%;
}

.audio-container {
  display: flex;
  align-items: center;
}

.custom-audio {
  width: 120px; /* Adjust width */
  height: 30px; /* Adjust height */
  margin-right: 8px; /* Add some space between the audio and stop button */
}

.data_table table .td .view_btn{
  background: var(--warning);
  font-size: 12px;
  border: none;
  border-radius: 2px;
  color: white;
}
.data_table table .td img{
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.data_table table .td .revenue_span{
font-weight: 700;
width: 100%;
letter-spacing: 1px;
}

 .data_table table .td .received_Status{
  background-color: var(--primary);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

 .data_table table .td .process_Status,.data_table table .td .unactive{
  background-color: var(--critical);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
 .data_table table .td .completed_Status{
  background-color: var(--warning);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.data_table table .td .edit_btn{
  background-color: var(--primary);
  border-radius: 2px;
  color: white;
  font-size: 11px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  width: 100%;
}
 .data_table table .td .delivered_Status, .data_table table .td .active{
  background-color: var(--success);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
 .data_table table .td .unactive{
  background-color: var(--b_Red);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.data_table table .td .paid{
  background-color: var(--rating);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.data_table table .td .trial{
  background-color:#ED6A3F;
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.data_table table .td .unpaid{
  background-color: var(--critical);
  border-radius: 100px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.data_table table .td .active_span{
  color: var(--success);
  font-weight: 600;
}
.data_table table .td .inactive_span{
  color: var(--b_Red);
  font-weight: 600;
}
.data_table table .td .pending_span{
  color: rgb(231, 161, 161);
  font-weight: 600;
}
.data_table table .td .rejected_span{
  color: var(--b_Red);
  font-weight: 600;
}
.data_table table .td .activate_shops{
  background-color: var(--success);
  border-radius: 2px;
  color: white;
  font-size: 11px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  width: 100%;
}

.data_table table .td .inactivate_shops{
  background-color: var(--b_Red);
  border-radius: 2px;
  color: white;
  font-size: 11px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Tailors Activate/Inactivate alert modal */
.shops_modal .container{
background-color: white;

}

.shops_modal .alert_icon .activate_icon{
  background-color: var(--success);
}
.shops_modal .alert_icon .inactivate_icon{
  background-color: var(--b_Red);
}
.shops_modal .alert_icon svg{
  display: inline;
  height: 45px;
  color: rgb(253, 253, 253);
  padding: 6px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur */
  -webkit-backdrop-filter: blur(5px); /* Support for Safari */
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
.shops_modal .alert_icon h6{
  font-size: 20px;
  color: black;
  font-weight: 700;
}
.shops_modal .alert_icon p{
  font-size: 13px;
  color: black;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.shops_modal .cancel{
  font-size: 12px;
  border: 1px solid var(--main_bg);
  cursor: pointer;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px solid black;
  background: none;
  border-radius: 4px;
}
.shops_modal select,.shops_modal input{
  width: 100%;
  height: 35px;
  background: none;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 12px;
  color: black;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-transform: capitalize;
}
.shops_modal select:focus,.shops_modal input:focus{
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
/* Hide number input spinners */
 input[type=number]::-webkit-outer-spin-button,
 input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shops_modal .activate{
  font-size: 12px;
  border: 1px solid var(--main_bg);
  cursor: pointer;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px solid black;
  background: var(--success);
  border-radius: 4px;
  color: white;
}
.shops_modal .inactivate{
  font-size: 12px;
  border: 1px solid var(--main_bg);
  cursor: pointer;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px solid black;
  background: var(--b_Red);
  border-radius: 4px;
  color: white;
}

.shops_modal .image-container {
  max-height: 80vh; /* Ensure the image container height doesn't exceed the viewport height */
  max-width: 60vh;
 
}

.shops_modal .image-container img {
  max-height: 100%;  /* Image will adjust to fit within the height of the container */
  max-width: 100%;  
  /* object-fit: contain;  */
}


/* Custom date filter */
.shops_modal .container{
  background-color: white;
  
  }
  .custom_date_modal  h6{
    font-size: 18px;
    color: black;
    font-weight: 700;
  }

  .custom_date_modal .input{
    height: 20px;
    display: inline;
  text-transform: capitalize;
  }
  
  .custom_date_modal .cancel{
    font-size: 14px;
    border: 1px solid var(--main_bg);
    cursor: pointer;
    font-weight: 500;
    box-shadow: 1px 1px 1px 1px solid black;
    background: none;
    border-radius: 4px;
    width: 80px;
  }
  
  .custom_date_modal .active{
    font-size: 13px;
    border: 1px solid var(--main_bg);
    cursor: pointer;
    font-weight: 600;
    box-shadow: 1px 1px 1px 1px solid black;
    background: black;
    border-radius: 4px;
    color: white;
    width: 80px;
  }

.details_text h4{
  font-size: 16px;
  color: var(--primary);
}
.details_text p{
  font-size: 14px;
  color: black;
  letter-spacing: 0.6px;
}
/* Packages and Trials */
.packages .nav {
  display: flex;           /* Use flexbox to arrange items in a line */
  flex-wrap: nowrap;       /* Prevent wrapping of the buttons */
  overflow-x: auto;        /* Make the nav scrollable on the x-axis */
  width: 100%;
  padding-bottom: 10px;    /* Optional: Add padding to avoid scrollbar overlap */
}
.packages .nav-item {
  flex: 0 0 auto;          /* Prevent flex items from growing or shrinking */
  margin-right: 8px;       /* Space between items */
}
.packages .nav button {
  height: 35px;
  width: 100%;
  max-width: auto;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid var(--primary);
  background-color: transparent;
  color: var(--primary);
  min-width: 100px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: block;
}

.packages .nav button:hover {
  background-color: var(--primary);
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.packages .nav .nav-item .active {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: white;
  min-width: 100px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Trials Card */
.packages .trial_card {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.packages .trial_card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.packages .trial_card .data {
  position: relative;
  margin-bottom: 16px;
}

.packages .trial_card .card-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  letter-spacing: 0.7px;
  background-color: #f8f9fa;
  padding: 10px;
}

.packages .trial_card input,
.packages .trial_card select {
  width: 100%;
  height: 40px;
  background: none;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 32px;
  color: black;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-transform: capitalize;

}

.packages .trial_card input:focus,
.packages .trial_card select:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.packages .trial_card .data svg {
  position: absolute;
  height: 17px;
  top: 16px;
  left: 8px;
  color: black;
}

.packages .trial_card .data i {
  position: absolute;
  font-size: 14px;
  top: 18px;
  left: 12px;
}

.packages .trial_card .data .active {
  color: var(--success);
}

.packages .trial_card .data .inactive {
  color: var(--b_Red);
}

/* Custom Styling to Remove the Dropdown Arrow for Select Element */
.packages .trial_card select::-ms-expand {
  display: none; /* For Internet Explorer */
}
/* Style for Edit, Update, and Cancel buttons */
.trial_card .btn {
  margin-right: 5px; /* Add spacing between buttons */
  padding: 6px 12px; /* Adjust button padding */
  font-size: 0.9rem; /* Font size adjustment */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Edit Button Styles */
.trial_card .edit_btn {
  background-color: var(--secondary); /* Primary button color */
  color: #fff;
  border: none;
}
.trial_card .edit_btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Update Button Styles */
.trial_card .save_btn {
  background-color: var(--success); /* Success button color */
  color: #fff;
  border: none;
}
.trial_card .save_btn:hover {
  background-color: #218838; /* Darker shade on hover */
}

/* Cancel Button Styles */
.trial_card .cancel_btn {
  background-color: var(--critical); /* Danger button color */
  color: #fff;
  border: none;
}
.trial_card .cancel_btn:hover {
  background-color: #c82333; /* Darker shade on hover */
}

/* Adding a new package card */
.add_new_package_card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.add_new_package_card .data {
  position: relative;
  margin-bottom: 16px;
}

.add_new_package_card .card-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  letter-spacing: 0.7px;
  background-color: #f8f9fa;
  padding: 10px;
  position: sticky;
}
.add_new_package_card label{
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.add_new_package_card input,
.add_new_package_card select {
  width: 100%;
  height: 35px;
  background: none;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 10px;
  color: black;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-transform: capitalize;
}

.add_new_package_card textarea {
  width: 100%;
  height: 70px;
  background: none;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 10px;
  color: black;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-transform: capitalize;
}

.add_new_package_card input:focus,
.add_new_package_card select:focus,
.add_new_package_card textarea:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.add_new_package_card .data svg {
  position: absolute;
  height: 17px;
  top: 16px;
  left: 8px;
  color: black;
}

.add_new_package_card .data i {
  position: absolute;
  font-size: 14px;
  top: 18px;
  left: 12px;
}

.add_new_package_card .data .active {
  color: var(--success);
}

.add_new_package_card .data .inactive {
  color: var(--b_Red);
}

/* Custom Styling to Remove the Dropdown Arrow for Select Element */
.add_new_package_card select::-ms-expand {
  display: none; /* For Internet Explorer */
}
/* Style for Edit, Update, and Cancel buttons */
.add_new_package_card .btn {
  margin-right: 5px; /* Add spacing between buttons */
  padding: 6px 12px; /* Adjust button padding */
  font-size: 0.9rem; /* Font size adjustment */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Edit Button Styles */
.add_new_package_card .edit_btn {
  background-color: var(--secondary); /* Primary button color */
  color: #fff;
  border: none;
}
.add_new_package_card .edit_btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Update Button Styles */
.add_new_package_card .save_btn {
  background-color: var(--success); /* Success button color */
  color: #fff;
  border: none;
}
.add_new_package_card .save_btn:hover {
  background-color: #218838; /* Darker shade on hover */
}

/* Cancel Button Styles */
.add_new_package_card .cancel_btn {
  background-color: var(--critical); /* Danger button color */
  color: #fff;
  border: none;
}
.add_new_package_card .cancel_btn:hover {
  background-color: #c82333; /* Darker shade on hover */
}
.add_new_package_card .add_btn,.add_new_package_card .remove_btn{
  font-size: 18px;
  height: 35px;
  min-width: 35px;
  background-color: transparent;
  border-radius: 4px;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  }
  .image-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  }
  .profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  }
  .add-icon {
  position: absolute;
  bottom: 3px;
  right: 8px;
  background-color: var(--primary);
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid white;
  }


/* Revennues */
.revenue-container {
  display: flex;
  gap: 1rem; /* Space between cards */
  overflow-x: auto; /* Enable horizontal scrolling when cards overflow */
  padding: 1rem;
  scroll-snap-type: x mandatory; /* Smooth scrolling for better user experience */
}

.package-card {
  flex: 1 0 250px; /* Flexibly grow, but have a minimum width */
  max-width: 100%; /* Max width for each card */
  min-width: 250px; /* Min width for each card */
  scroll-snap-align: center;
}

.package-revenue {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for visual appeal */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 7px;
  background-color: var(--natural);
  
}

.package-revenue:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.package-revenue .card-body {
  padding: 1rem;
}

.package-revenue .left h6 {
  color: #3a3a3a;
  font-weight: bold;
  font-size: 1.1rem;
}

.package-revenue .right p {
  font-size: 1rem;
  color: #007bff;
  font-weight: bold;
}




.custom-shop-selector .form-control {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 12px;
  color: black;
  font-size: 14px;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: none; /* Removes any background image */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-transform: capitalize;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.list-group-item {
  padding: 8px 12px;
  color: black;
  font-size: 14px;
  border-radius: 0px;
  text-transform: capitalize;
}
